import React, {useEffect} from 'react';
import styled from 'styled-components';
import * as AppActions from '../../AppActions';
import * as JStorageActions from '../../Actions/JStorage';
import {replaceHTMLBlankWithLineBreak} from '../../Utils/HtmlUtil';

function OnlineMenuPage() {
  const [article, setArticle] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        AppActions.setLoading(true);
        const resp = await JStorageActions.fetchDocuments('Article_Default', {
          label: 'online-menu',
        });
        setArticle(resp.results?.[0]);
      } catch (ex) {
      } finally {
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (!article) {
    return null;
  }

  return (
    <Wrapper>
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: replaceHTMLBlankWithLineBreak(article.html),
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: var(--topNavBarHeight) 40px 0 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #000000;

  & > .content {
    max-width: var(--contentMaxWith);
    margin: 0 auto;
    padding: var(--basePadding);
    white-space: pre-line;
  }
  @media screen and (max-width: 767px) {
    padding: var(--topNavBarHeight) 10px 0 10px;
    & > .content {
      padding: 0;
    }
  }
`;

export default OnlineMenuPage;
